import { objectToQueryString } from 'jslib/utils/utils';

class ApiManager {
    constructor() {
        this.apiUrl = '/api';
    }

    getQueryStringFromOptions(options) {
        if (!options.query) {
            return;
        }

        return objectToQueryString(options.query);
    }

    getEndpointUrl(elementType, elementId, options = {}) {
        let endpointUrl = this.apiUrl;

        // Return early if the elementType is not defined
        if (!elementType || !elementType.length) {
            return;
        }

        endpointUrl = `${endpointUrl}/${elementType}`;

        if (options.search && options.search.length) {
            endpointUrl = `${endpointUrl}/search?term=${options.search}`;
        } else if (elementId && parseInt(elementId, 10) > 0) {
            endpointUrl = `${endpointUrl}/${elementId}`;
        }

        if (!options.search) {
            endpointUrl = `${endpointUrl}.json`;
        }

        if (options.query) {
            const queryString = this.getQueryStringFromOptions(options);

            endpointUrl = `${endpointUrl}?${queryString}`;
        }

        return endpointUrl;
    }

    async fetchData(elementType, elementId = null, options = {}) {
        let url;

        if (options.url) {
            url = options.url;

            if (options.query) {
                const queryString = this.getQueryStringFromOptions(options);

                url = `${url}?${queryString}`;
            }
        } else {
            url = this.getEndpointUrl(elementType, elementId, options);
        }

        if (!url) {
            throw new Error('No url specified!');
        }

        const response = await fetch(url, {
            headers: {
                Accept: 'application/json',
            },
            method: 'GET',
        });

        return await response.json();
    }

    async getJwt(endpoint) {
        const url = `${this.apiUrl}/${endpoint}/token.json`;
        const response = await fetch(url, {
            headers: {
                Accept: 'application/json',
            },
        });

        return await response.json();
    }
}

export default ApiManager;
