const appNamespace = 'pulse';

export default {
    analytics: {
        track: `${appNamespace}:analytics:track`,
    },
    animation: {
        DOMLoaded: `${appNamespace}:animation:domLoaded`,
    },
    autocomplete: {
        search: `${appNamespace}:autocomplete:search`,
    },
    carousel: {
        resetBreakpoints: `${appNamespace}:carousel:resetBreakpoints`,
    },
    contentSearch: {
        search: `${appNamespace}:contentSearch:search`,
        results: `${appNamespace}:contentSearch:results`,
        clear: `${appNamespace}:contentSearch:clear`,
    },
    filteredSearch: {
        start: `${appNamespace}:filteredSearch:start`,
        results: `${appNamespace}:filteredSearch:results`,
    },
    forms: {
        error: `${appNamespace}:forms:error`,
        resetForm: `${appNamespace}:forms:resetForm`,
        resetValidation: `${appNamespace}:forms:resetValidation`,
        success: `${appNamespace}:forms:success`,
    },
    formValidator: {
        valid: `${appNamespace}:formValidator:valid`,
        invalid: `${appNamespace}:formValidator:invalid`,
        showError: `${appNamespace}:formValidator:showError`,
        removeError: `${appNamespace}:formValidator:removeError`,
    },
    images: {
        lazyLoaded: `${appNamespace}:images:lazyLoaded`,
    },
    interests: {
        updated: `${appNamespace}:interests:updated`,
        unselected: `${appNamespace}:interests:unselected`,
    },
    interestToggle: {
        clicked: `${appNamespace}:interestToggle:clicked`,
    },
    journeyActionsControl: {
        addToBasket: `${appNamespace}:journeyActionControl:addToBasket`,
        shareJourney: `${appNamespace}:journeyActionControl:shareJourney`,
    },
    lists: {
        loaded: `${appNamespace}:lists:loaded`,
        updated: `${appNamespace}:lists:updated`,
    },
    navigation: {
        closeAll: `${appNamespace}:nav:closeAll`,
    },
    notifications: {
        notify: `${appNamespace}:notifications:notifiy`,
        close: `${appNamespace}:notifications:close`,
    },
    popover: {
        hideAll: `${appNamespace}:popover:hideAll`,
    },
    screenshotViewer: {
        setScrollPosition: `${appNamespace}:screenshotViewer:setScrollPosition`,
        switchViewerMode: `${appNamespace}:screenshotViewer:switchViewerMode`,
    },
    subnav: {
        itemInView: `${appNamespace}:subnav:itemInView`,
    },
    table: {
        refresh: `${appNamespace}:table:refresh`,
        refreshed: `${appNamespace}:table:refreshed`,
    },
    toggleSwitch: {
        changed: `${appNamespace}:toggleSwitch:changed`,
    },
    tooltip: {
        hideAll: `${appNamespace}:tooltip:hideAll`,
    },
    topResultsSearch: {
        results: `${appNamespace}.topResultsSearch:results`,
        noResults: `${appNamespace}.topResultsSearch:noResults`,
    },
    vimeoPlayer: {
        cuePoint: `${appNamespace}:vimeoPlayer:cuepoint`,
        ready: `${appNamespace}:vimeoPlayer:ready`,
        enterCinemaMode: `${appNamespace}:vimeoPlayer:enterCinemaMode`,
        exitCinemaMode: `${appNamespace}:vimeoPlayer:exitCinemaMode`,
    },
    vimeoSkipTrigger: {
        active: `${appNamespace}:vimeoSkipTrigger:active`,
        inactive: `${appNamespace}:vimeoSkipTrigger:inactive`,
    },
};
