import { DateTime } from 'luxon';

const loadSvgSprite = (spriteUrl) => {
    fetch(spriteUrl, {
        method: 'GET',
    })
        .then((response) => response.text())
        .then((data) => {
            const div = document.createElement('div');

            div.innerHTML = data;
            div.classList.add('svg-sprite');
            document.body.insertBefore(div, document.body.childNodes[0]);
        });
};

const getUrlParameter = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const objectToQueryString = (object) => {
    const params = [];

    for (const property in object) {
        if (Object.prototype.hasOwnProperty.call(object, property)) {
            params.push(`${encodeURIComponent(property)}=${encodeURIComponent(object[property])}`);
        }
    }

    return params.join('&');
};

const getCookie = (name) => {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

const setCookie = (name = '', value = '', maxAge) => {
    let cookie;

    if (name.length && value.length) {
        cookie = `${name}=${encodeURIComponent(value)};path=/`;

        if (maxAge) {
            cookie = `${cookie};max-age=${maxAge}`;
        }
    }

    if (cookie && cookie.length) {
        document.cookie = cookie;
    }
};

const getOrdinal = (day) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;

    return day + (s[(v - 20) % 10] || s[v] || s[0]);
};

const getUnixTimestamp = () => {
    return Math.floor(Date.now() / 1000);
};

const secondsToMinutes = (seconds) => Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);

const getRelativePublishDate = (unixTimestamp) => {
    if (!unixTimestamp) {
        return;
    }

    const dateCreated = DateTime.fromSeconds(unixTimestamp);
    const isToday = DateTime.now().hasSame(dateCreated, 'days');
    const isYesterday = DateTime.now().minus({ days: 1 }).hasSame(dateCreated, 'days');
    let datePosted = dateCreated.toRelative();

    if (isToday) {
        datePosted = dateCreated.toRelativeCalendar();
    } else if (isYesterday) {
        datePosted = DateTime.now().minus({ days: 1 }).toRelative();
    }

    if (datePosted.match(/^a\s{1}/)) {
        datePosted = `1 ${datePosted.split('a ')[1]}`;
    }

    return datePosted;
};

const getPublishDateTimestamp = (unixTimestamp) => {
    if (!unixTimestamp) {
        return;
    }

    return DateTime.fromSeconds(unixTimestamp).toISODate();
};

const getPublishDate = (unixTimestamp) => {
    if (!unixTimestamp) {
        return;
    }

    const date = DateTime.fromSeconds(unixTimestamp);
    const ordinalDay = getOrdinal(date.toFormat('d'));

    return `${ordinalDay} ${date.toFormat('LLLL yyyy')}`;
};

const getNamespace = (element) => {
    if (!element) {
        return;
    }

    return element.dataset.namespace ?? window.location.pathname.split('/')?.[1];
};

const dec2hex = (decimal) => {
    return ('0' + decimal.toString(16)).substr(-2);
};

const generateUid = (length = 40) => {
    const seed = new Uint8Array(length / 2);
    const crypto = window.crypto || window.msCrypto;

    crypto.getRandomValues(seed);

    return Array.from(seed, dec2hex).join('');
};

const secondsToTime = (seconds, substrFormat = [11, 8]) => {
    return new Date(seconds * 1000).toISOString().substr(substrFormat[0], substrFormat[1]);
};

const replaceElementContents = (parentEl, childEl, htmlString) => {
    const htmlEl = document.createRange().createContextualFragment(htmlString);

    if (childEl) {
        childEl.replaceWith(htmlEl);
    } else {
        parentEl.appendChild(htmlEl);
    }
};

const getElementDimensions = (element) => {
    const elStyle = getComputedStyle(element);
    const width = parseInt(elStyle.width.replace('px', ''), 10);
    const height = parseInt(elStyle.height.replace('px', ''), 10);
    const paddingWidth =
        parseInt(elStyle.paddingLeft.replace('px', ''), 10) + parseInt(elStyle.paddingRight.replace('px', ''), 10);
    const paddingHeight =
        parseInt(elStyle.paddingTop.replace('px', ''), 10) + parseInt(elStyle.paddingBottom.replace('px', ''), 10);

    return {
        width: width,
        height: height,
        innerWidth: width - paddingWidth,
        innerHeight: height - paddingHeight,
        paddingWidth: paddingWidth,
        paddingHeight: paddingHeight,
    };
};

const formatRatingValue = (value) => {
    return (value / 10).toFixed(1);
};

const getSearchStateUrl = (contentType, state, baseUrl = PULSE.search.url) => {
    const urlParts = [`${baseUrl}?`];
    const indexConfig = PULSE.search.config?.[contentType];
    let indexName = '';

    // Get the default index name for this content type
    if (indexConfig) {
        indexName = indexConfig.defaultIndex;
    } else {
        throw new Error(`No search index defined for content type '${contentType}'`);
    }

    // Build URL from state
    for (const [stateKey, stateValue] of Object.entries(state)) {
        for (const [itemKey, itemValue] of Object.entries(stateValue)) {
            if (Array.isArray(itemValue)) {
                itemValue.forEach((value, index) => {
                    if (value) {
                        const partKey = encodeURIComponent(`[${stateKey}][${itemKey}][${index}]`);
                        const partValue = encodeURIComponent(value.replace(/<[^>]*>/g, '').replaceAll('&amp;', '&'));

                        urlParts.push(`${indexName}${partKey}=${partValue}&`);
                    }
                });
            } else {
                if (itemValue) {
                    const partKey = encodeURIComponent(`[${stateKey}][${itemKey}]`);
                    const partValue = encodeURIComponent(itemValue.replace(/<[^>]*>/g, '').replaceAll('&amp;', '&'));

                    urlParts.push(`${indexName}${partKey}=${partValue}&`);
                }
            }
        }
    }

    // Create initial URL string and remove trailing ampersand
    let urlString = urlParts.join('').replace(/&$/, '');

    // Add in tab ID
    urlString = `${urlString}#tab-${indexConfig.tabId}`;

    return urlString;
};

const addAlgoliaHighlights = (resultItem) => {
    if (resultItem?._highlightResult) {
        for (const [key, value] of Object.entries(resultItem._highlightResult)) {
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    // Allow country codes to be highlighted when country title is a match
                    if (key === 'geographies' || key === 'location') {
                        if (item.title.matchLevel === 'full') {
                            item.countryCode.matchLevel = 'full';
                            item.countryCode.value = `<em>${item.countryCode.value}</em>`;
                        }
                    }

                    // Add highlights over the top of existing values
                    for (const [itemKey, itemValue] of Object.entries(item)) {
                        resultItem[key][index][itemKey] = itemValue.value;
                        resultItem[key][index].matched = itemValue.matchLevel !== 'none';
                    }
                });
            } else {
                resultItem[key] = value.value;
            }
        }
    }

    if (resultItem?._snippetResult) {
        for (const [key, value] of Object.entries(resultItem._snippetResult)) {
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    for (const [itemKey, itemValue] of Object.entries(item)) {
                        if (itemValue.matchLevel !== 'none') {
                            resultItem[key][index][itemKey] = itemValue.value;
                        }
                    }
                });
            } else {
                if (value.matchLevel !== 'none') {
                    resultItem[key] = value.value;
                }
            }
        }
    }
};

const isItemTrending = (id) => {
    return PULSE.trending.items.includes(id);
};

const getTrendingBadge = () => {
    return PULSE.trending.badge;
};

export {
    loadSvgSprite,
    getUrlParameter,
    objectToQueryString,
    secondsToMinutes,
    getRelativePublishDate,
    getPublishDateTimestamp,
    getPublishDate,
    getNamespace,
    getCookie,
    setCookie,
    generateUid,
    secondsToTime,
    getUnixTimestamp,
    replaceElementContents,
    getElementDimensions,
    formatRatingValue,
    getSearchStateUrl,
    addAlgoliaHighlights,
    isItemTrending,
    getTrendingBadge,
};
